import React, { useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { Container } from "react-bootstrap";
import Alert from "../Alert";
import AppealsList from "./AppealsList";
import AppealForm from "./AppealForm";
import Rank from "../../constants/Rank";

const AppealsPage = () => {
  const user = React.useContext(UserContext);

  const [loaded, setLoaded] = useState(false);
  const [alert, setAlert] = useState({
    message: null,
    variant: "success"
  });

  useEffect(() => {
    if (!user.loading) {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.loading]);

  if (!loaded) return "";

  return <Container className={"mb-5"}>
    {Rank.isSuperStaff(user) ? <AppealsList setAlert={setAlert}/> : <AppealForm setAlert={setAlert} user={user}/>}
    <Alert
      message={alert.message}
      show={!!alert.message}
      variant={alert.variant}
      onHide={() => setAlert({ ...alert, message: null })}
    />
  </Container>;
}

export default AppealsPage;