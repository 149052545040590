import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Card, Container, Row, Spinner } from "react-bootstrap";
import { linkDiscord } from "../../api/AuthApi";
import { useLocation } from "react-router-dom";
import linkIcon from "../../icons/link.svg"

export default () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    linkDiscord(params.get("code"), params.get("state"))
      .then(({ data }) => {
        if (data) {
          setContent(<>
            <div className="mb-5">
              <div className="display-3">Success!</div>
              <div className="lead">Your MineClick and Discord accounts are now linked</div>
              <div className="lead">You can close this page</div>
            </div>
            <Row className="justify-content-center">
              <div className="col-md-2 col">
                <Card className="mb-2">
                  <Card.Img variant="top" src={"https://crafatar.com/avatars/" + data.mcUuid}/>
                </Card>
                <div className="lead">
                  {data.mcUsername}
                </div>
              </div>
              <div className="col-md-2 col">
                <div className="mb-5">
                  <SVG
                    src={linkIcon}
                    style={{
                      height: "100%",
                      width: "100%",
                      fill: "#547cb9"
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 col">
                <Card className="mb-2">
                  <Card.Img variant="top"
                            src={"https://cdn.discordapp.com/avatars/" + data.id + "/" + data.avatar + ".png"}/>
                </Card>
                <div className="lead">
                  {data.username + "#" + data.discriminator}
                </div>
              </div>
            </Row>
          </>);
        }
      })
      .catch(() => {
        setContent(<h3>Invalid Discord linker url</h3>);
      })
      .finally(() => {
        setLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Row noGutters={true}>
    <Container>
      <div style={{ textAlign: "center", margin: 20 }}>
        {loading ? (
          <>
            <h3>Linking your Discord account...</h3>
            <Spinner animation="border" role="status" className={"mt-3"}>
              <span className="sr-only">Loading...</span>
            </Spinner>
          </>
        ) : content}
      </div>
    </Container>
  </Row>
};