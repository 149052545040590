import axios from 'axios';

const getAppeals = () => {
  return axios.get("/api/appeals");
};

const getAppealCase = (username) => {
  return axios.get("/api/appeal?username=" + username);
};

const postAppeal = (username, message) => {
  return axios.post("/api/appeal", { username, message });
};

const getChatHistory = (username) => {
  return axios.get("/api/appeals/chatHistory?username=" + username);
};

const postAppealStatus = (username, status) => {
  return axios.post("/api/appeals/status", { username, status });
};

export {
  getAppeals,
  getAppealCase,
  postAppeal,
  getChatHistory,
  postAppealStatus
};
