import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Alert as BootstrapAlert } from "react-bootstrap";

const height = 100;
const AlertWrapper = styled.div`
  position: fixed;
  left: 20px;
  z-index: 999;
  transition: all 0.5s cubic-bezier(0.4, 0.26, 0.22, 1.59);
`;

const Alert = (props) => {
  const {
    timeout = 5000,
    onHide,
    message,
    show,
    ...rest
  } = props;

  const [bottomPos, setBottomPos] = useState(-height);
  const [showTimer, setShowTimer] = useState(-1);

  const slide = (up = true) => {
    setBottomPos(up ? 0 : -height);
  };

  useEffect(() => {
    clearTimeout(showTimer);
    if (show) {
      if (timeout) {
        setShowTimer(setTimeout(() => {
          slide(false);

          setTimeout(onHide, 1000);
        }, timeout));
      }

      slide(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return show && (
    <AlertWrapper style={{ bottom: bottomPos }}>
      <BootstrapAlert {...rest} >
        {message}
      </BootstrapAlert>
    </AlertWrapper>
  );
};

export default Alert;