import { UserContext } from "../context/UserContext";
import React, { useEffect, useState } from "react";
import history from "../helpers/history";
import { logout as logoutApi } from "../api/AuthApi";
import { getUser } from "../api/UsersApi";

const UserProvider = (props) => {
  const [user, setUser] = useState({
    loading: true,
    loggedIn: false,
    logout: () => {
      setUser({
        ...user,
        loading: true
      });

      return logoutApi().then(() => {
        history.push("/");

        setUser({
          ...user,
          loading: false,
          loggedIn: false
        });
      });
    },
    login: () => {
      setUser({
        ...user,
        loading: true
      });

      return getUser().then((response) => {
        setUser({
          ...user,
          ...(response.data || {}),
          loading: false,
          loggedIn: true
        });
      }).catch(() => {
        setUser({
          ...user,
          loading: false,
          loggedIn: false
        })
      })
    }
  });

  useEffect(() => {
    user.login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={user}>
      {props.children}
    </UserContext.Provider>
  )
};

export default UserProvider;