import React, { useEffect, useState } from "react";
import { getAnnouncements, setAnnouncements } from "../../../api/SettingsApi";
import { Form, Row } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import StyledButton from "../../styled/StyledButton";
import Col from "react-bootstrap/Col";

const Announcements = (props) => {
  const { setAlert } = props;

  const [loaded, setLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    announcements: [],
    interval: ""
  });

  useEffect(() => {
    getAnnouncements().then(({ data }) => {
      setLoaded(true);
      setInitialValues({
        ...data
      });
    }).catch(() => {
      setAlert({
        message: "Error loading announcements",
        variant: "danger"
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className={"mb-3 mt-5"}>
        Announcements
      </h4>
      <Formik
        enableReinitialize
        onSubmit={(data, { setSubmitting }) => {
          const announcements = data.announcements.reduce((acc, val) => {
            if (val) {
              return [...acc, val];
            }
            return acc;
          }, []);

          setAnnouncements({
            announcements,
            interval: data.interval
          })
            .then(() => {
              setAlert({
                message: announcements.length + " announcements were saved",
                variant: "success"
              });
            })
            .catch(() => {
              setAlert({
                message: "Error updating announcements",
                variant: "danger"
              });
            })
            .finally(() => {
              setSubmitting(false);
              setInitialValues({
                announcements,
                interval: data.interval
              });
            });
        }}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.interval) {
            errors.interval = "Interval is required"
          }
          if (isNaN(values.interval)) {
            errors.interval = "Not a number";
          }
          if (values.interval <= 0) {
            errors.interval = "Must be a positive number";
          }

          return errors;
        }}
      >
        {({
            handleSubmit,
            isSubmitting,
            values,
            errors,
            dirty,
            handleChange,
            handleBlur,
            submitForm
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FieldArray
              name="announcements"
              render={arrayHelpers => (
                <div>
                  {values.announcements && values.announcements.length > 0 ? (
                    values.announcements.map((announcement, index) => (
                      <Form.Row key={index}>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            name={`announcements.${index}`}
                            value={announcement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!loaded}
                          />
                        </Form.Group>
                        <Form.Group>
                          <StyledButton.Primary
                            type="button"
                            style={{ margin: "0 2px", width: "40px" }}
                            disabled={!loaded}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            -
                          </StyledButton.Primary>
                        </Form.Group>
                        <Form.Group>
                          <StyledButton.Primary
                            type="button"
                            style={{ margin: "0 2px", width: "40px" }}
                            disabled={!loaded}
                            onClick={() => arrayHelpers.insert(index + 1, "")}
                          >
                            +
                          </StyledButton.Primary>
                        </Form.Group>
                      </Form.Row>
                    ))
                  ) : (
                    <StyledButton.Primary
                      type="button"
                      className={"mb-3"}
                      disabled={!loaded}
                      onClick={() => arrayHelpers.push("")}
                    >
                      Add an announcement
                    </StyledButton.Primary>
                  )}
                  {!!(values.announcements || []).length && (
                    <Row>
                      <Col>
                        <StyledButton.Primary
                          type="button"
                          disabled={isSubmitting || !loaded || !dirty}
                          onClick={submitForm}
                        >
                          Save Announcements
                        </StyledButton.Primary>
                      </Col>
                      <Col md={{ span: "auto" }}>
                        <Row>
                          <Form.Label column md={"auto"}>Time interval in minutes</Form.Label>
                          <Col md={"auto"}>
                            <Form.Control
                              type="text"
                              name="interval"
                              value={values.interval}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!loaded}
                              isInvalid={!!errors.interval}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.interval}
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
      <hr/>
    </>
  );
};

export default Announcements;