import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import Image from "react-shimmer";
import history from "../helpers/history";
import { UserContext } from "../context/UserContext";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar, Overlay, Tooltip } from "react-bootstrap";
import LoginModal from "./LoginModal";
import Alert from "./Alert";
import Rank from "../constants/Rank";

const styles = {
  container: {
    backgroundColor: "#185886"
  }
};

const UserHead = styled.span`
  margin-right: 10px;
  display: inline-block;
`;

const IpNav = styled(Nav)`
  background-color: #000000;
  padding: 2px 15px;
  font-weight: bold;
  border: 2px solid white;
  color: white;
  cursor: pointer;
`;

const Header = () => {
  const user = React.useContext(UserContext);
  const [showSignIn, setShowSignIn] = useState(false);
  const [selectedLink, setSelectedLink] = useState(
    (history.location.pathname || "").replace("/", "")
  );
  const [showAlert, setShowAlert] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const ipTarget = useRef(null);
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    setDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (didMount) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username]);

  const createLink = (path, label = null, rank = null) => {
    return (!rank || rank(user)) && (<Nav.Link
      href={"/" + path}
      onClick={(e) => {
        e.preventDefault();

        setSelectedLink(path);
        history.push("/" + path);
        document.title = "MineClick Ender" + (path && " - " + path.charAt(0).toUpperCase() + path.slice(1));
      }}
      active={selectedLink === path}
    >
      {label || path.charAt(0).toUpperCase() + path.slice(1)}
    </Nav.Link>);
  };

  return (
    <Container fluid={true} style={styles.container}>
      <Container>
        <Navbar collapseOnSelect expand={"lg"} variant={"dark"}>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/logo189.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            MineClick Ender
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {createLink("", "Servers")}
              {createLink("appeals")}
              {createLink("players", null, Rank.isSuperStaff)}
              {createLink("settings", null, Rank.isDev)}
            </Nav>
            <IpNav
              ref={ipTarget}
              className="mr-auto"
              onClick={(e) => {
                const range = document.createRange();
                range.selectNodeContents(e.target);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);

                document.execCommand('copy');

                setShowCopied(true);
                setTimeout(() => {
                  setShowCopied(false);
                  sel.removeAllRanges();
                }, 3000);
              }}
            >
              {"play.mineclick.net"}
              <Overlay target={ipTarget.current} show={showCopied} placement="right">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
            </IpNav>
            <Nav>
              <Nav.Link
                onClick={(e) => {
                  e.preventDefault();

                  if (user.loggedIn) {
                    user.logout();
                  } else {
                    setShowSignIn(true);
                  }
                }}
                active={showSignIn}
                disabled={user.loading}
              >
                {user.uuid && (
                  <UserHead>
                    <Image
                      src={"https://crafatar.com/avatars/" + user.uuid}
                      width={20}
                      height={20}
                      style={{ verticalAlign: "text-bottom" }}
                      fallback={<div/>}
                    />
                  </UserHead>
                )}
                {user.loggedIn ? "Logout" : "Login"}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <LoginModal
        show={showSignIn}
        onHide={() => setShowSignIn(false)}
      />
      <Alert
        variant={user.username ? "success" : "warning"}
        show={showAlert}
        message={user.username ? `Welcome ${user.username}` : "Logged out"}
        onHide={() => setShowAlert(false)}
      />
    </Container>
  );
};

export default Header;