import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getMotd, setMotd } from "../../../api/SettingsApi";

import styled from "styled-components";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import StyledButton from "../../styled/StyledButton";
import ChatColors from "../../../constants/ChatColors";

const CenteredGroup = styled(Col)`
  @media (min-width: 768px) {
    height: calc(1.5em + .75rem + 2px);
    margin-top: calc(1.5em + .75rem + 2px);
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`;

const ColorButton = styled(Button)`
  color: #${props => props.color.textColor} !important;
  background-color: #${props => props.color.hex} !important;
  border-color: #000000 !important;
  
  :hover, :focus, :active {
    color: #${props => props.color.textColor} !important;
    background-color: #${props => props.color.hex} !important;
    border-color: #000000 !important;
  }
`;

const Motd = (props) => {
  const { setAlert } = props;
  const schema = Yup.object({
    top: Yup.string().required("Top line of MOTD is required"),
    topAppendix: Yup.string(),
    topCentered: Yup.bool(),
    bottom: Yup.string(),
    bottomCentered: Yup.bool(),
    minVersion: Yup.number().default(0)
  });

  const [fieldInFocus, setFieldInFocus] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    top: "",
    topAppendix: "",
    topCentered: false,
    bottom: "",
    bottomCentered: false,
    minVersion: 0
  });

  useEffect(() => {
    getMotd().then(({ data }) => {
      setLoaded(true);
      setInitialValues({
        ...data
      });
    }).catch(() => {
      setAlert({
        message: "Error loading MOTD",
        variant: "danger"
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className={"mb-3 mt-5"}>MOTD Settings</h4>
      <Formik
        enableReinitialize
        validationSchema={schema}
        onSubmit={(data, { setSubmitting }) => {
          setMotd(data)
            .then(() => {
              setAlert({
                message: "MOTD was successfully updated",
                variant: "success"
              })
            })
            .catch(() => {
              setAlert({
                message: "Error updating MOTD",
                variant: "danger"
              });
            })
            .finally(() => {
              setSubmitting(false);
              setInitialValues({
                ...data
              });
            });
        }}
        initialValues={initialValues}
      >
        {({
            handleSubmit,
            isSubmitting,
            values,
            dirty,
            handleChange,
            handleBlur,
            errors,
            submitForm,
            setFieldValue
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="1" controlId="minVersion">
                <Form.Label>Min version</Form.Label>
                <Form.Control
                  type="text"
                  name="minVersion"
                  value={values.minVersion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => setFieldInFocus(e)}
                  isInvalid={!!errors.minVersion}
                  disabled={!loaded}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="top">
                <Form.Label>Top line</Form.Label>
                <Form.Control
                  type="text"
                  name="top"
                  value={values.top}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => setFieldInFocus(e)}
                  isInvalid={!!errors.top}
                  disabled={!loaded}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.top}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="1" controlId="topAppendix">
                <Form.Label>Appendix</Form.Label>
                <Form.Control
                  type="text"
                  name="topAppendix"
                  value={values.topAppendix}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => setFieldInFocus(e)}
                  isInvalid={!!errors.topAppendix}
                  disabled={!loaded}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.topAppendix}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={CenteredGroup} md="3" controlId="topCentered">
                <Form.Check
                  type="switch"
                  name="topCentered"
                  label="Top line centered"
                  checked={values.topCentered}
                  onChange={() => {
                    setFieldValue("topCentered", !values.topCentered);
                  }}
                  onBlur={handleBlur}
                  isInvalid={!!errors.topCentered}
                  disabled={!loaded}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="9" controlId="bottom">
                <Form.Label>Bottom line</Form.Label>
                <Form.Control
                  type="text"
                  name="bottom"
                  value={values.bottom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => setFieldInFocus(e.target)}
                  isInvalid={!!errors.bottom}
                  disabled={!loaded}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bottom}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={CenteredGroup} md="3" controlId="bottomCentered">
                <Form.Check
                  type="switch"
                  name="bottomCentered"
                  label="Bottom line centered"
                  checked={values.bottomCentered}
                  onChange={() => {
                    setFieldValue("bottomCentered", !values.bottomCentered);
                  }}
                  onBlur={handleBlur}
                  isInvalid={!!errors.bottomCentered}
                  disabled={!loaded}
                />
              </Form.Group>
            </Form.Row>
            <Row>
              <Col md={{ order: 2 }} className={"mb-3"}>
                <Row noGutters={true}>
                  {
                    Object
                      .keys(ChatColors)
                      .map(key => {
                        const color = ChatColors[key];
                        return (
                          <ColorButton
                            key={color.code}
                            type="button"
                            color={color}
                            className={"mx-1 mb-2"}
                            disabled={isSubmitting || !loaded}
                            onClick={() => {
                              if (fieldInFocus) {
                                const name = fieldInFocus.name;
                                setFieldValue(name, values[name] + "&" + color.code);
                                fieldInFocus.focus();
                              }
                            }}
                          >
                            &{color.code} {key}
                          </ColorButton>
                        );
                      })
                  }
                </Row>
              </Col>
              <Col md={{ order: 1, span: "auto" }}>
                <StyledButton.Primary
                  type="button"
                  disabled={isSubmitting || !loaded || !dirty}
                  onClick={submitForm}
                >
                  Save MOTD
                </StyledButton.Primary>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <hr/>
    </>
  )
};

export default Motd;