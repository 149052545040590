import React, { useEffect, useState } from 'react';
import moment from "moment";

import ServerStatus from "../../constants/ServerStatus";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const styles = {
  card: {
    margin: 15,
    borderWidth: 2
  }
};

const ServerCard = props => {
  const {
    id,
    status,
    players,
    cpuLoad,
    ramUsage,
    game,
    startedOn,
    restartsOn
  } = props.server;
  const { index } = props;

  const [restartTime, setRestartTime] = useState(null);

  useEffect(() => {
    const count = () => {
      if (restartsOn) {
        const when = moment(restartsOn);
        const now = moment(new Date());

        if (when.isSameOrAfter(now)) {
          setRestartTime(moment.utc(when.diff(now)).format("HH:mm:ss"));
          return;
        }
      }

      setRestartTime(null);
    };
    count();

    const timer = setInterval(count, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeItem = (label, value) => {
    return <ListGroup.Item className="d-flex justify-content-between h6">
      {label}
      <span className="text-muted">{value}</span>
    </ListGroup.Item>
  };

  const color = ServerStatus[status].color;
  return (
    <Card
      key={id}
      border={color}
      style={styles.card}
    >
      <Card.Header
        className={`border${color} text-center h4`}
        style={{ borderRadius: 0 }}
      >
        {game ? "Server" : "Build"} {index + 1}
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <ListGroup variant="flush">
          {makeItem("Players", players)}
          {makeItem("Status", ServerStatus[status].label)}
          {makeItem("CPU load", cpuLoad + "%")}
          {makeItem("RAM usage", ramUsage + "%")}
          {makeItem("Id", id)}
        </ListGroup>
      </Card.Body>
      <Card.Footer>
        <div className={"d-flex justify-content-between"}>
          <OverlayTrigger
            placement={"bottom"}
            overlay={<Tooltip>Started on</Tooltip>}
          >
            <small className="text-muted">{moment(startedOn).format("YYYY-MM-DD HH:mm:ss")}</small>
          </OverlayTrigger>
          {restartTime && status === "RUNNING" && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>Restarts in</Tooltip>}
            >
              <small className="text-muted">{restartTime}</small>
            </OverlayTrigger>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
};

ServerCard.defaultProps = {
  server: {
    status: "Loading...",
    players: 0,
    cpuLoad: 0,
    ramUsage: 0,
    game: true,
    startedOn: "Loading..."
  }
};

export default ServerCard;