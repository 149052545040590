import AceEditor from "react-ace";
import React, { useEffect } from "react";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

const PlayerInfo = (props) => {
  const editorRef = React.createRef();
  const { player } = props;

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.editor.session.foldAll(1, editorRef.current.editor.session.doc.getAllLines().length);
    }
  }, [editorRef]);

  return <div style={{ border: "2px solid #ededed" }}>
    <AceEditor
      ref={editorRef}
      mode="json"
      theme="github"
      value={JSON.stringify(player, null, '\t')}
      height={"800px"}
      width={"100%"}
      name="playerInfo"
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        showPrintMargin: false,
        tabSize: 2,
        fontSize: 15,
        readOnly: true
      }}
    />
  </div>;
};

export default PlayerInfo;