import React from 'react';
import { Chart } from 'react-charts';
import { Col } from "react-bootstrap";

const ServersChart = (props) => {
  const data = React.useMemo(
    () => {
      return [
        {
          label: 'Players',
          data: props.data
        }
      ];
    },
    [props.data]
  );

  const series = React.useMemo(() => ({
    showPoints: false,
  }), []);

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'utc', position: 'bottom', showGrid: false, show: false },
      { type: 'linear', position: 'left', showGrid: false, show: false, hardMin: -0.5 }
    ],
    []
  );

  const getSeriesStyle = React.useCallback(() => ({
    color: `#185886`,
    transition: 'all .5s ease'
  }), []);

  const getDatumStyle = React.useCallback(() => ({
    transition: 'all .5s ease'
  }), []);

  const tooltip = React.useMemo(() => ({
    align: "auto",
    anchor: "closest",
    render: ({ datum }) => {
      const date = datum ? datum.group[0].primary : null;
      return date ? date.toLocaleString() : "";
    }
  }), []);

  return <Col style={{ height: 200 }}>
    <Chart
      data={data}
      axes={axes}
      series={series}
      getSeriesStyle={getSeriesStyle}
      getDatumStyle={getDatumStyle}
      tooltip={tooltip}
      // primaryCursor
      secondaryCursor
    />
  </Col>
};

export default ServersChart;