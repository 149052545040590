import React, { useEffect, useState } from "react";
import { getConfigurations, setConfigurations } from "../../../api/SettingsApi";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-elixir";
import "ace-builds/src-noconflict/theme-textmate";
import StyledButton from "../../styled/StyledButton";

const Configurations = (props) => {
  const { setAlert } = props;

  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [loadedValue, setLoadedValue] = useState("");

  useEffect(() => {
    getConfigurations().then(({ data }) => {
      setLoaded(true);

      if (data.configurations) {
        setValue(data.configurations);
        setLoadedValue(data.configurations);
      }
    }).catch(() => {
      setAlert({
        message: "Error loading configurations",
        variant: "danger"
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className={"mb-3 mt-5"}>
        Configurations
      </h4>
      <div className={"mb-3"}>
        <StyledButton.Primary
          type="button"
          disabled={!loaded || value === loadedValue}
          onClick={() => {
            setLoaded(false);
            setConfigurations(
              {
                configurations: value
              }
            ).then(() => {
              setAlert({
                message: "Configurations were saved",
                variant: "success"
              });
            }).catch(() => {
              setAlert({
                message: "Error saving configurations",
                variant: "danger"
              });
            }).finally(() => {
              setLoaded(true);
              setLoadedValue(value);
            });
          }}
        >
          Save Configurations
        </StyledButton.Primary>
      </div>
      <div style={{ border: "2px solid #ededed" }}>
        <AceEditor
          mode="elixir"
          theme="textmate"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          height={"800px"}
          width={"100%"}
          name="config"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            showPrintMargin: false,
            tabSize: 2,
            fontSize: 15,
            enableLiveAutocompletion: true,
            readOnly: !loaded
          }}
        />
      </div>
    </>
  );
};

export default Configurations;