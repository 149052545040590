import axios from 'axios';
import Api from "../constants/Api";

const getUser = () => {
  return axios.get(Api.url("/user"), { withCredentials: true });
};

export {
  getUser
};
