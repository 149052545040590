export default {
  black: {
    code: "0",
    hex: "000000",
    textColor: "FFFFFF"
  },
  darkBlue: {
    code: "1",
    hex: "0000AA",
    textColor: "FFFFFF"
  },
  darkGreen: {
    code: "2",
    hex: "00AA00",
    textColor: "FFFFFF"
  },
  darkAqua: {
    code: "3",
    hex: "00AAAA",
    textColor: "FFFFFF"
  },
  darkRed: {
    code: "4",
    hex: "AA0000",
    textColor: "FFFFFF"
  },
  darkPurple: {
    code: "5",
    hex: "AA00AA",
    textColor: "FFFFFF"
  },
  gold: {
    code: "6",
    hex: "FFAA00",
    textColor: "000000"
  },
  gray: {
    code: "7",
    hex: "AAAAAA",
    textColor: "000000"
  },
  darkGray: {
    code: "8",
    hex: "555555",
    textColor: "FFFFFF"
  },
  blue: {
    code: "9",
    hex: "5555FF",
    textColor: "FFFFFF"
  },
  green: {
    code: "a",
    hex: "55FF55",
    textColor: "000000"
  },
  aqua: {
    code: "b",
    hex: "55FFFF",
    textColor: "000000"
  },
  red: {
    code: "c",
    hex: "FF5555",
    textColor: "000000"
  },
  lightPurple: {
    code: "d",
    hex: "FF55FF",
    textColor: "000000"
  },
  yellow: {
    code: "e",
    hex: "FFFF55",
    textColor: "000000"
  },
  white: {
    code: "f",
    hex: "FFFFFF",
    textColor: "000000"
  },
  obfuscated: {
    code: "k",
    hex: "FFFFFF",
    textColor: "000000"
  },
  bold: {
    code: "l",
    hex: "FFFFFF",
    textColor: "000000"
  },
  strikethrough: {
    code: "m",
    hex: "FFFFFF",
    textColor: "000000"
  },
  underscore: {
    code: "n",
    hex: "FFFFFF",
    textColor: "000000"
  },
  italic: {
    code: "o",
    hex: "FFFFFF",
    textColor: "000000"
  },
  reset: {
    code: "r",
    hex: "FFFFFF",
    textColor: "000000"
  }
};