import axios from 'axios';
import Api from "../constants/Api";

const getMotd = () => {
  return axios.get(Api.url("/settings/motd"), { withCredentials: true });
};

const getAnnouncements = () => {
  return axios.get(Api.url("/settings/announcements"), { withCredentials: true });
};

const getConfigurations = () => {
  return axios.get(Api.url("/settings/configurations"), { withCredentials: true });
};

const setMotd = (data) => {
  return axios.post(Api.url("/settings/motd"), data, { withCredentials: true });
};

const setAnnouncements = (data) => {
  return axios.post(Api.url("/settings/announcements"), data, { withCredentials: true });
};

const setConfigurations = (data) => {
  return axios.post(Api.url("/settings/configurations"), data, { withCredentials: true });
};

export {
  getMotd,
  setMotd,
  getAnnouncements,
  setAnnouncements,
  getConfigurations,
  setConfigurations
};
