import React, { useState } from 'react';
import * as Sections from "./sections";
import { Container } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import Alert from "../Alert";
import Rank from "../../constants/Rank";

const SettingsPage = () => {
  const user = React.useContext(UserContext);

  const [alert, setAlert] = useState({
    message: null,
    variant: "success"
  });

  return Rank.isDev(user) ? (
    <Container className={"mb-5"}>
      <Sections.Motd setAlert={setAlert}/>
      <Sections.Announcements setAlert={setAlert}/>
      <Sections.Configurations setAlert={setAlert}/>
      <Alert
        message={alert.message}
        show={!!alert.message}
        variant={alert.variant}
        onHide={() => setAlert({ ...alert, message: null })}
      />
    </Container>
  ) : "";
};

export default SettingsPage;
