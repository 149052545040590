import styled from "styled-components";
import { Button } from "react-bootstrap";

export default {
  Primary: styled(Button)`
    background-color: #547cb9 !important;
    border-color: #5f8dd3 !important;

    :hover, :focus, :active {
      background-color: #5f8dd3 !important;
      border-color: #5f8dd3 !important;
    }
  `
}