const isStaff = (user) => {
  return user.rank === "STAFF" || isSuperStaff(user) || isDev(user);
};
const isSuperStaff = (user) => {
  return user.rank === "SUPER_STAFF" || isDev(user);
};
const isDev = (user) => {
  return user.rank === "DEV";
};

export default {
  isStaff,
  isSuperStaff,
  isDev
};