import React, { Fragment, useState } from "react";
import { getAppealCase, postAppeal } from "../../api/AppealsApi";

import { Card, Col, Form, FormControl, Jumbotron, ListGroup, Row, Spinner } from "react-bootstrap";
import StyledButton from "../styled/StyledButton";
import moment from "moment";

const statusColors = {
  BANNED: "warning",
  APPEALED: "primary",
  DENIED: "danger",
  PARDONED: "success"
};

const AppealForm = (props) => {
  const { setAlert, user } = props;

  const [loaded, setLoaded] = useState(true);
  const [appeal, setAppeal] = useState(null)
  const [username, setUsername] = useState(user ? user.username : null)
  const [appealMessage, setAppealMessage] = useState(null)

  const getAppeal = (username) => {
    if (!loaded) return;

    setLoaded(false);
    getAppealCase(username)
      .then((response) => {
        if (!response.data || !response.data.offences.length) {
          setAlert({
            message: "There are no active infractions under this username",
            variant: "warning"
          });
        } else {
          setAppeal(response.data);
          setAppealMessage((((response.data || {}).offences || [])[0] || {}).appealMessage);
        }
      })
      .catch(() => {
        setAlert({
          message: "Error submitting your request, please try again later",
          variant: "danger"
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (username) {
      getAppeal(username);
    } else {
      setAlert({
        message: "Username is required",
        variant: "danger"
      });
    }
  };

  const submitAppeal = () => {
    if (!loaded) return;

    setLoaded(false);
    postAppeal(username, appealMessage)
      .then((response) => {
        setAlert({
          message: "Your ban appeal has been submitted. Check this page later again",
          variant: "success"
        });

        setAppeal(response.data);
      })
      .catch(() => {
        setAlert({
          message: "Error submitting your appeal. Please try again or refresh the page",
          variant: "danger"
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }

  const offence = ((appeal || {}).offences || [])[0] || {};

  return <div className="text-center">
    <Jumbotron className={"mt-4"}>
      {!appeal ? (
        <div className="col-lg-6 col-md-10 col-sm-12 m-auto">
          <h4 className={"mb-4 font-weight-normal text-muted"}>
            Enter your username to appeal your ban or check the status of your appeal
          </h4>
          <Form noValidate onSubmit={onSubmit}>
            <Form.Group controlId="username">
              <Form.Control
                type="text"
                placeholder="Username (case sensitive)"
                required
                value={username || ""}
                onChange={(e) => setUsername(e.target.value)}
                disabled={!loaded}
              />
            </Form.Group>

            <StyledButton.Primary
              type={"submit"}
              disabled={!loaded}
            >
              {"Submit"}
              {!loaded && (
                <>
                  {" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              )}
            </StyledButton.Primary>
          </Form>
        </div>
      ) : (
        <Fragment>
          <Row>
            <Col md={3}>
              <img
                src={"https://crafatar.com/renders/body/" + appeal.uuid}
                className={"mb-4"}
                alt=""
              />
              <h4>{username}</h4>
            </Col>
            <Col>
              <Card>
                <Card.Header
                  className={"text-center " +
                  "font-weight-bold " +
                  (offence.status !== "BANNED" ? "text-light " : "") +
                  "bg-" + statusColors[offence.status]}
                >
                  {offence.status}
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span className={"font-weight-bold"}>Offence: </span>
                    {offence.offenceReason}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className={"font-weight-bold"}>Banned on: </span>
                    {moment.utc(offence.punishedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className={"font-weight-bold"}>Ban duration: </span>
                    {offence.durationMinutes < 0 ? "permanent" : offence.durationMinutes + " minutes"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className={"font-weight-bold"}>Banned by: </span>
                    {offence.punishedBy}
                  </ListGroup.Item>
                  {!!offence.appealedOn && (
                    <ListGroup.Item>
                      <span className={"font-weight-bold"}>Appealed on: </span>
                      {moment.utc(offence.appealedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                    </ListGroup.Item>
                  )}
                  {offence.status === "DENIED" && (
                    <Fragment>
                      <ListGroup.Item>
                        <span className={"font-weight-bold"}>Denied by: </span>
                        {offence.deniedBy}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span className={"font-weight-bold"}>Denied on: </span>
                        {moment.utc(offence.deniedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                      </ListGroup.Item>
                    </Fragment>
                  )}
                  {offence.status === "PARDONED" && (
                    <Fragment>
                      <ListGroup.Item>
                        <span className={"font-weight-bold"}>Pardoned by: </span>
                        {offence.pardonedBy}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span className={"font-weight-bold"}>Pardoned on: </span>
                        {moment.utc(offence.pardonedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                      </ListGroup.Item>
                    </Fragment>
                  )}
                </ListGroup>
              </Card>
            </Col>
          </Row>
          {(offence.status === "BANNED" || offence.status === "APPEALED") && (
            <Row className={"m-0"}>
              <h4 className={"mt-4 font-weight-normal text-muted"}>
                Reason for appeal:
              </h4>
              <FormControl
                as="textarea"
                value={appealMessage || ""}
                onChange={(e) => {
                  if (e.target.value.length <= 1000) {
                    setAppealMessage(e.target.value);
                  }
                }}
                disabled={!loaded || offence.status !== "BANNED"}
              />
              {offence.status === "BANNED" && (
                <StyledButton.Primary
                  className={"mt-2"}
                  type={"button"}
                  disabled={!loaded}
                  onClick={submitAppeal}
                >
                  {"Submit appeal"}
                  {!loaded && (
                    <>
                      {" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </StyledButton.Primary>
              )}
            </Row>
          )}
        </Fragment>
      )}
    </Jumbotron>
  </div>;
};

export default AppealForm;