export default {
  STARTING: {
    color: "primary",
    label: "Starting"
  },
  RUNNING: {
    color: "success",
    label: "Running"
  },
  WAITING_TO_RESTART: {
    color: "warning",
    label: "Waiting to restart"
  },
  RESTARTING: {
    color: "danger",
    label: "Restarting"
  },
  NO_RESPONSE: {
    color: "secondary",
    label: "No response"
  }
};