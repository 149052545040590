import React, { Fragment, useEffect, useState } from "react";
import { getAppeals, getChatHistory, postAppealStatus } from "../../api/AppealsApi";
import { Button, Card, Col, FormControl, Jumbotron, ListGroup, Row, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import StyledButton from "../styled/StyledButton";

const AppealsList = (props) => {
  const { setAlert } = props;

  const [loaded, setLoaded] = useState(false);
  const [appeals, setAppeals] = useState([]);
  const [chatHistory, setChatHistory] = useState({});

  useEffect(() => {
    setLoaded(false);
    getAppeals()
      .then((response) => {
        setAppeals(response.data);
      })
      .catch((error) => {
        let message = "Unable to load appeals. Try again"
        if (error.response
          && error.response.data
          && error.response.data.message) {
          message = error.response.data.message;
        }

        setAlert({
          message,
          variant: "danger"
        });
      })
      .finally(() => {
        setLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadChatHistory = (username) => {
    setLoaded(false);
    getChatHistory(username)
      .then((response) => {
        setChatHistory({
          ...chatHistory,
          [username]: response.data
        });
      })
      .catch((error) => {
        let message = "Unable to load chat history. Try again"
        if (error.response
          && error.response.data
          && error.response.data.message) {
          message = error.response.data.message;
        }

        setAlert({
          message,
          variant: "danger"
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const setStatus = (username, status) => {
    setLoaded(false);
    postAppealStatus(username, status)
      .then((response) => {
        setAppeals(appeals.map(appeal => appeal.name === username ? response.data : appeal));

        setAlert({
          message: (status === "BANNED" ? "Undone" : status) + " " + username + "'s appeal",
          variant: "success"
        });
      })
      .catch((error) => {
        let message = "Unable to set the appeal status. Try again"
        if (error.response
          && error.response.data
          && error.response.data.message) {
          message = error.response.data.message;
        }

        setAlert({
          message,
          variant: "danger"
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  return <Fragment>
    {!loaded && !appeals.length
      ? <div style={{ textAlign: "center", margin: 20 }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
      : <Fragment>
        <h3 className={"m-4 text-center"}>
          {appeals.length ? "Active Ban Appeals (" + appeals.length + ")" : "There are no ban appeals"}
        </h3>
        {appeals.map((appeal, index) => {
          const offence = (appeal.offences || [])[0];
          if (!offence) return null;

          return <Jumbotron className={"mt-4"} key={index}>
            <Row>
              <Col md={3} className={"text-center"}>
                <img
                  src={"https://crafatar.com/renders/body/" + appeal.uuid}
                  className={"mb-4"}
                  alt=""
                />
                <h4>{appeal.name}</h4>
              </Col>
              <Col>
                <Card>
                  <Card.Header className={"text-center font-weight-bold"}>
                    {offence.offenceReason}
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <span className={"font-weight-bold"}>Banned on: </span>
                      {moment.utc(offence.punishedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span className={"font-weight-bold"}>Ban duration: </span>
                      {offence.durationMinutes < 0 ? "permanent" : offence.durationMinutes + " minutes"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span className={"font-weight-bold"}>Banned by: </span>
                      {offence.punishedBy}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span className={"font-weight-bold"}>Appealed on: </span>
                      {moment.utc(offence.appealedOn).local().format("YYYY-MM-DD HH:mm:ss")}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
            <Row className={"m-0"}>
              <h4 className={"mt-4 font-weight-normal text-muted"}>
                Reason for appeal:
              </h4>
              <FormControl
                as="textarea"
                value={offence.appealMessage || ""}
                disabled={true}
              />
            </Row>
            <Row className={"m-0 flex-column"}>
              <h4 className={"mt-4 font-weight-normal text-muted"}>
                Chat history:
              </h4>
              {!chatHistory[appeal.name] ? (
                <StyledButton.Primary
                  style={{ maxWidth: 150 }}
                  className={"mt-2"}
                  type={"button"}
                  disabled={!loaded}
                  onClick={() => loadChatHistory(appeal.name)}
                >
                  Load chat history
                </StyledButton.Primary>
              ) : (
                <Table striped bordered hover size="sm">
                  <tbody>
                  {chatHistory[appeal.name].map((msg, i) => (
                    <tr key={i}>
                      <td>{msg}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              )}
            </Row>
            <Row className={"m-0 flex-column"}>
              <h4 className={"mt-4 font-weight-normal text-muted"}>
                {"Appeal action: "}
                {(offence.status === "PARDONED" || offence.status === "DENIED")
                && <span className={"text-" + (offence.status === "DENIED" ? "danger" : "success")}>
                {offence.status}
              </span>}
              </h4>
              {(offence.status === "PARDONED" || offence.status === "DENIED") ? (
                <Row noGutters className={"flex-column"}>
                  <Button
                    className={"mt-2"}
                    type={"button"}
                    variant={"dark"}
                    disabled={!loaded}
                    onClick={() => setStatus(appeal.name, "BANNED")}
                  >
                    Undo
                  </Button>
                </Row>
              ) : (
                <Row noGutters className={"d-flex"}>
                  <Button
                    className={"mt-2 mr-2 flex-grow-1"}
                    type={"button"}
                    variant={"success"}
                    disabled={!loaded}
                    onClick={() => setStatus(appeal.name, "PARDONED")}
                  >
                    Approve
                  </Button>
                  <Button
                    className={"mt-2 ml-2 flex-grow-1"}
                    type={"button"}
                    variant={"danger"}
                    disabled={!loaded}
                    onClick={() => setStatus(appeal.name, "DENIED")}
                  >
                    Deny
                  </Button>
                </Row>
              )}
            </Row>
          </Jumbotron>;
        })}
      </Fragment>}
  </Fragment>;
};

export default AppealsList;