import React, { useEffect, useState } from 'react';

import ServerCard from "./ServerCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { getServers } from "../../api/ServersApi";
import ServersChart from "./ServersChart";

const styles = {
  col: {
    minWidth: 300,
    maxWidth: 300
  }
};

const ServersPage = () => {
  const [servers, setServers] = useState([]);
  const [players, setPlayers] = useState([]);

  const loadServers = () => {
    getServers().then(response => {
      if (response.data) {
        setServers(response.data.servers || []);

        // parse players log
        const parsedLog = (response.data.playersLog || []).map(entry => {
          const split = entry.split(" ");
          return [new Date(split[0]), split[1]];
        });
        setPlayers(parsedLog);
      } else {
        setServers([]);
        setPlayers([]);
      }
    });
  };

  useEffect(() => {
    loadServers();
    const timer = setInterval(loadServers, 3000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const length = servers.length;
  let content;
  if (!length) {
    content = (
      <div style={{ textAlign: "center", margin: 20 }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    content = (
      <>
        <Row className={"justify-content-center mt-3"} noGutters={true}>
          <h2>{servers.reduce((acc, s) => acc + s.players, 0)} players online</h2>
        </Row>
        <Row className={"my-3"} noGutters={true}>
          <ServersChart data={players}/>
        </Row>
        <Row noGutters={true} className={"justify-content-center"}>
          {servers
            .sort((a, b) => (a.id || "").localeCompare(b.id))
            .map((s, i) => {
              return (
                <Col key={i} md={{ span: 3 }} style={styles.col}>
                  <ServerCard server={s} index={i}/>
                </Col>
              );
            })}
        </Row>
      </>
    );
  }

  return (
    <Row noGutters={true}>
      <Container>
        {content}
      </Container>
    </Row>
  );
};

ServersPage.defaultProps = {
  servers: []
};

export default ServersPage;