import React, { useState } from "react";
import Rank from "../../constants/Rank";
import { Container } from "react-bootstrap";
import Alert from "../Alert";
import { UserContext } from "../../context/UserContext";
import PlayerSearch from "./PlayerSearch";
import PlayerInfo from "./PlayerInfo";

const PlayersPage = () => {
  const user = React.useContext(UserContext);
  const [player, setPlayer] = useState(null);

  const [alert, setAlert] = useState({
    message: null,
    variant: "success"
  });

  return Rank.isSuperStaff(user) ? (
    <Container className={"mb-5"}>
      <PlayerSearch setPlayer={setPlayer}/>
      {!!player && <PlayerInfo player={player}/>}
      <Alert
        message={alert.message}
        show={!!alert.message}
        variant={alert.variant}
        onHide={() => setAlert({ ...alert, message: null })}
      />
    </Container>
  ) : "";
};

export default PlayersPage;