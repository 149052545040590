import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { getPlayer } from "../../api/PlayersApi";
import StyledButton from "../styled/StyledButton";

const PlayerSearch = (props) => {
  const {
    setPlayer
  } = props;
  const focusRef = React.createRef();
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    if (search) {
      setInput(search);
    }
  }, []);

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusRef]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!!error) return;
    if (!input) {
      setError("Search value cannot be empty");
      return
    }

    setLoading(true);
    getPlayer(input)
      .then(({ data }) => {
        setPlayer(data || null);
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setError("Not found");
        } else {
          setError(error.response?.data?.message || "");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <div className="text-center">
    <h4 className={"mb-3 mt-5"}>Player search</h4>
    <Form noValidate onSubmit={onSubmit}>
      <Form.Row>
        <Form.Group as={Col} md="10" controlId="search">
          <Form.Control
            ref={focusRef}
            placeholder="Enter username or UUID"
            type="text"
            name="search"
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              setError("");
            }}
            isInvalid={!!error}
            disabled={loading}
          />
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <StyledButton.Primary
            style={{ width: "100%" }}
            type={"submit"}
            onClick={onSubmit}
          >
            Search
          </StyledButton.Primary>
        </Form.Group>
      </Form.Row>
    </Form>
  </div>
};

export default PlayerSearch;