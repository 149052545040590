import React from 'react';
import { Redirect, Route, Router, Switch } from "react-router-dom";
import history from "./helpers/history";

import Container from 'react-bootstrap/Container';

import Header from "./component/Header";
import ServersPage from "./component/servers/ServersPage";
import SettingsPage from "./component/settings/SettingsPage";
import UserProvider from "./component/UserProvider";
import DiscordLinkerPage from "./component/discord/DiscordLinkerPage";
import AppealsPage from "./component/appeals/AppealsPage";
import PlayersPage from "./component/players/PlayersPage";

const App = () => {
  return (
    <Router history={history}>
      <UserProvider>
        <Container fluid={true} style={{ padding: 0 }}>
          <Header/>
          <Switch>
            <Route exact path={"/"}>
              <ServersPage/>
            </Route>
            <Route exact path={"/settings"}>
              <SettingsPage/>
            </Route>
            <Route exact path={"/discord"}>
              <DiscordLinkerPage/>
            </Route>
            <Route exact path={["/appeal", "/appeals"]}>
              <AppealsPage/>
            </Route>
            <Route exact path={["/player", "/players"]} >
              <PlayersPage/>
            </Route>
            <Route path={"*"}>
              <Redirect to={"/"}/>
            </Route>
          </Switch>
        </Container>
      </UserProvider>
    </Router>
  );
};

export default App;