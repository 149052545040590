import axios from 'axios';
import Api from "../constants/Api";

const login = (data) => {
  return axios.post(Api.url("/auth/login"), data, { withCredentials: true });
};

const logout = () => {
  return axios.get(Api.url("/auth/logout"), { withCredentials: true });
};

const register = (data) => {
  return axios.post(Api.url("/auth/register"), data, { withCredentials: true });
};

const resetPassword = (data) => {
  return axios.post(Api.url("/auth/resetPassword"), data, { withCredentials: true });
};

const resendOtpCode = (username) => {
  return axios.get(Api.url("/auth/resendotp"), { params: username });
};

const linkDiscord = (code, state) => {
  return axios.get(Api.url("/auth/discord"), { params: { code, state } });
};

export {
  login,
  logout,
  register,
  resetPassword,
  resendOtpCode,
  linkDiscord
};
