import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import styled from "styled-components";

import { authStatusTypes } from "../constants/Auth";

import infoIcon from "../icons/password_info.svg"
import infoIconAnimated from "../icons/password_info_animated.svg"

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import StyledButton from "./styled/StyledButton";
import { login, register, resendOtpCode, resetPassword } from "../api/AuthApi";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { UserContext } from "../context/UserContext";

const LoginBody = styled(Modal)`
  & > div {
    max-width: 420px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
`;

const PasswordRulesUl = styled.ul`
  padding-left: 18px;
  text-align: left;
  margin-bottom: 0;
`;

const [LOGIN, REGISTER, RESET_PASSWORD] = ["Login", "Register", "Reset password"];

const LoginModal = (props) => {
  const user = React.useContext(UserContext);
  const focusRef = React.createRef();
  const [formValidated, setFormValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const [mode, setMode] = useState(LOGIN);
  const [status, setStatus] = useState("");
  const [shownSections, setShownSections] = useState({})
  const [otpSignature, setOtpSignature] = useState(null)
  const [serverError, setServerError] = useState(false);
  const [otpResendDisabled, setOtpResendDisabled] = useState(false);

  useEffect(() => {
    if (props.show && focusRef.current) {
      focusRef.current.focus();
    }

    setFormValues({
      ...formValues,
      password: "",
      verifyPassword: "",
    });
    setFormErrors({});

    setShownSections({
      password: mode === LOGIN || status === authStatusTypes.validatedOtp,
      verifyPassword: mode !== LOGIN && status === authStatusTypes.validatedOtp,
      otp: status === authStatusTypes.awaitingOtp
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, mode, status]);

  useEffect(() => {
    clear();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    setStatus("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clear = () => {
    setFormValues({});
    setFormErrors({});
    setMode(LOGIN);
    setStatus("");
    setLoading(false);
    setOtpSignature(null);
  };

  const closeModal = () => {
    clear();
    props.onHide();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFormErrors({});

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setFormValidated(true);
      return;
    }

    if (mode !== LOGIN) {
      if (status === authStatusTypes.validatedOtp) {
        if (formValues.password !== formValues.verifyPassword) {
          setFormErrors({
            verifyPassword: "Passwords do not match"
          });

          setFormValidated(false);
          return;
        }
      }

      (mode === REGISTER ? register : resetPassword)({
        ...formValues,
        otpSignature
      })
        .then(response => {
          const { status: resStatus, otpSignature: signature, error } = response.data;
          setOtpSignature(signature);

          if (error) {
            if (resStatus === authStatusTypes.awaitingOtp) {
              setFormErrors({
                otp: error
              });
            } else if (resStatus === authStatusTypes.validatedOtp) {
              setFormErrors({
                password: error
              });
            } else {
              setFormErrors({
                username: error
              });
            }
          }
          setStatus(resStatus);

          if (resStatus === authStatusTypes.registered) {
            user.login();
            closeModal();
          }
        })
        .catch((error) => {
          if (error.response
            && error.response.data
            && error.response.data.message) {
            setFormErrors({
              username: error.response.data.message
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      login(formValues)
        .then(() => user.login())
        .then(() => closeModal())
        .catch((error) => {
          if (error.response
            && error.response.data
            && error.response.data.message) {
            setFormErrors({
              username: error.response.data.message
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setFormValidated(false);
    setLoading(true);
    setServerError(null);
  };

  const onOTPResendClick = () => {
    setOtpResendDisabled(true);

    resendOtpCode({
      username: formValues.username
    }).finally(() => {
      setTimeout(() => setOtpResendDisabled(false), 30000);
    });
  };

  return (
    <>
      <LoginBody
        {...props}
        centered
      >
        <Modal.Body>
          <CloseButton type="button" className="close" onClick={() => closeModal()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </CloseButton>
          <div className={"text-center mb-4"}>
            <img className={"mb-1"}
                 src={"./logo189.png"}
                 alt=""
                 width={72}
                 height={72}
            />
            <h3 className={"mb-4"}>MineClick Ender</h3>
            <h4 className="mb-4 font-weight-normal text-muted">
              {mode}
            </h4>

            <Form noValidate validated={formValidated} onSubmit={onSubmit}>
              <Form.Group controlId="username">
                <Form.Control
                  type="text"
                  placeholder="Username (case sensitive)"
                  ref={focusRef}
                  required
                  value={formValues.username || ""}
                  onChange={(e) => setFormValues({
                    ...formValues,
                    username: e.target.value
                  })}
                  disabled={(mode !== LOGIN && status) || loading}
                  isInvalid={formErrors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.username}
                </Form.Control.Feedback>
              </Form.Group>

              {shownSections.otp && (
                <Form.Group controlId="otp">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Enter your OTP"
                      required
                      value={formValues.otp}
                      onChange={(e) => setFormValues({
                        ...formValues,
                        otp: e.target.value
                      })}
                      isInvalid={formErrors.otp}
                      disabled={loading}
                    />
                    <InputGroup.Append>
                      {(!otpResendDisabled && (
                        <Button variant={"outline-secondary"} onClick={onOTPResendClick}>
                          Resend
                        </Button>
                      )) || (
                        <InputGroup.Text>Sent</InputGroup.Text>
                      )}
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {formErrors.otp}
                    </Form.Control.Feedback>
                  </InputGroup>
                  {!formErrors.otp && (
                    <Form.Text className="text-info text-left">
                      One Time Password was sent to you in the game chat
                    </Form.Text>
                  )}
                </Form.Group>
              )}

              {shownSections.password && (
                <Form.Group controlId="password">
                  <InputGroup>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      value={formValues.password || ""}
                      onChange={(e) => setFormValues({
                        ...formValues,
                        password: e.target.value
                      })}
                      isInvalid={formErrors.password}
                      disabled={loading}
                    />
                    {mode !== LOGIN && (
                      <InputGroup.Append>
                        <OverlayTrigger
                          placement={"bottom"}
                          overlay={<Tooltip id={"password-rules"}>
                            <p>Password must have</p>
                            <PasswordRulesUl>
                              <li>Minimum 8 characters</li>
                              <li>One upper case letter</li>
                              <li>One lower case letter</li>
                              <li>One number</li>
                            </PasswordRulesUl>
                          </Tooltip>}
                        >
                          <InputGroup.Text>
                            <SVG src={formErrors.password ? infoIconAnimated : infoIcon}/>
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup.Append>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {formErrors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              )}

              {shownSections.verifyPassword && (
                <Form.Group controlId="passwordVerify">
                  <Form.Control
                    type="password"
                    placeholder="Verify password"
                    required
                    value={formValues.verifyPassword}
                    onChange={(e) => setFormValues({
                      ...formValues,
                      verifyPassword: e.target.value
                    })}
                    isInvalid={formErrors.verifyPassword}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.verifyPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <StyledButton.Primary type={"submit"} disabled={loading}>
                {mode}
                {loading && (
                  <>
                    {" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                )}
              </StyledButton.Primary>
            </Form>
          </div>
          {serverError && (
            <div className={"text-center text-danger"}>
              {serverError}
            </div>
          )}
          <div className={"text-center mt-4"}>
            <Button variant={"link"} onClick={() => setMode(mode === LOGIN ? REGISTER : LOGIN)}>
              {mode === LOGIN ? REGISTER : LOGIN}
            </Button>
            {mode !== RESET_PASSWORD && (
              <Button variant={"link"} onClick={() => setMode(RESET_PASSWORD)}>
                {RESET_PASSWORD}
              </Button>
            )}
          </div>
        </Modal.Body>
      </LoginBody>
    </>
  );
};

export default LoginModal;